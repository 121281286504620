import 'jquery'
import 'geocomplete'
import Vue from 'vue'
import * as SentryIntegrations from '@sentry/integrations'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

// import App from '@/App.vue'
import Alert from '@/components/Alert.vue'
import PasswordInput from '@/components/forms/PasswordInput.vue'
import CheckoutStepBilling from '@/components/pages/CheckoutStepBilling.vue'
import CheckoutStepBillingV2 from '@/components/pages/CheckoutStepBillingV2.vue'

// Do setup for these other libs in separate files.
import '@/ext/bootstrap-vue'
import '@/ext/fontawesome'

// CSS entry point.
import '@/assets/css/main.scss'

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV != 'production'
Vue.config.performance = process.env.NODE_ENV != 'production'

if (window.Sentry) {
  // Setup Vue/Sentry error integration
  // See https://github.com/getsentry/sentry-javascript/issues/1854
  // We set SentryInitOptions in base.tpl since we need all the same settings to re-initialize here.
  const Sentry = window.Sentry
  const options = window.SentryInitOptions || {}
  const client = new Sentry.BrowserClient({
    ...window.SentryInitOptions,
    integrations: (integrations) => [
      ...integrations,
      new SentryIntegrations.Vue({Vue, logErrors: true}),
    ],
  })
  Sentry.getCurrentHub().bindClient(client)
}

// Globally available components
Vue.component('font-awesome-icon', FontAwesomeIcon)
// Vue.component('font-awesome-layers', FontAwesomeLayers)
// Vue.component('font-awesome-layers-text', // Our custom globally available components
Vue.component('ac-alert', Alert)
Vue.component('ac-password-input', PasswordInput)

// Individual page controllers
Vue.component('ac-checkout-step-billing', CheckoutStepBilling)
Vue.component('ac-checkout-step-billing-v2', CheckoutStepBillingV2)

// Global scripts init
import '@/global'

// Entry points import
import formsEntryPoint from '@/utils/forms'
window.ALOE = window.ALOE || {}
window.ALOE = {
  forms: formsEntryPoint,
}

// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

new Vue({
  el: '#app',
})
