/**
 * Scripts than run globally on every page load.
 */

$(function () {
  // Prevent double submits on all forms.
  $('form').on('submit', function () {
    let $form = $(this as Element)

    setTimeout(() => {
      $form.find('button[type=submit]').prop('disabled', true)
    }, 0)
  })
})
