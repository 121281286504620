export default {
  initGeoCompleteField: function ($address: JQuery) {
    let $form = $address.closest('form')

    // @ts-ignore
    $address
      .geocomplete({
        country: 'US',
        types: ['address'],
        details: $form,
        detailsAttribute: 'data-geo',
      })
      .bind('geocode:result', function (event, result) {
        // Once user selects, we need to set only the line 1 into the field.
        // Grab first line of full address in simplest way possible:
        // '54 Lexington Ave, New York, NY 10010, USA' => '54 Lexington Ave'
        let addressLine1 = result.formatted_address.split(', ')[0]
        // City is not always inside `administrative_area_level_1_short` either.
        // For example for "123 Main St, Brooklyn, NY" its inside  `sublocality_level_1`
        let city = result.formatted_address.split(', ')[1]

        $address.val(addressLine1)
        $form.find('[name=city]').val(city)
        $form.find('[name=address_line2]').trigger('focus')
      })

    // Workaround browser autocomplete shenanigans. /shrug
    // https://stackoverflow.com/a/30976223/26192
    setTimeout(function () {
      $address.attr('autocomplete', 'new-password')
    }, 500)
  },
}
