
import Vue from 'vue'
import {Component, Prop, Watch} from 'vue-property-decorator'

@Component({})
export default class Alert extends Vue {
  /**
   * Wraps a Boostrap Alert and adds icon.
   */
}
