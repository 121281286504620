import Vue from 'vue'

import {
  AlertPlugin,
  ButtonPlugin,
  LinkPlugin,
  FormInputPlugin,
  DropdownPlugin,
  ModalPlugin,
  TooltipPlugin,
  BNavItemDropdown,
} from 'bootstrap-vue'

Vue.use(AlertPlugin)
Vue.use(ButtonPlugin)
Vue.use(LinkPlugin)
Vue.use(DropdownPlugin)
Vue.use(FormInputPlugin)
Vue.use(ModalPlugin)
Vue.use(TooltipPlugin)

Vue.component('BNavItemDropdown', BNavItemDropdown)
