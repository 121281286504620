// Load only icons we need https://github.com/FortAwesome/vue-fontawesome
import {config, library} from '@fortawesome/fontawesome-svg-core'

// https://stackoverflow.com/a/58913667/26192
config.autoAddCss = false

// These next lines help during development, but we should never deploy with all icons bundled.
// import {fas} from '@fortawesome/free-solid-svg-icons';
// import {far} from '@fortawesome/free-regular-svg-icons';
// import {fab} from '@fortawesome/free-brands-svg-icons';
// library.add(fas, far, fab);

library.add(
  require('@fortawesome/free-brands-svg-icons/faTwitter').definition,
  require('@fortawesome/free-solid-svg-icons/faExclamationCircle').definition,
  require('@fortawesome/free-solid-svg-icons/faExternalLinkAlt').definition,
  require('@fortawesome/free-solid-svg-icons/faInfoCircle').definition,
  require('@fortawesome/free-solid-svg-icons/faQuestionCircle.js').definition
)
