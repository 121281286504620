
import Vue from 'vue'
import {Component, Prop} from 'vue-property-decorator'
import {
  loadStripe,
  Stripe,
  StripeElements,
  StripeElementsOptions,
  StripePaymentElement,
  StripePaymentElementOptions,
} from '@stripe/stripe-js'

@Component({})
export default class CheckoutStepBillingV2 extends Vue {
  /**
   * Stripe test numbers: https://stripe.com/docs/testing
   */
  @Prop()
  successUrl!: string

  @Prop({default: 'GET'})
  successUrlMethod!: 'GET' | 'POST'

  @Prop()
  serverError!: any

  @Prop({default: 'Enter your billing information'})
  headerCopy!: string

  @Prop({default: ''})
  stepIndicatorCopy!: string

  stripe: Stripe = null
  elements: StripeElements = null
  paymentElement: StripePaymentElement = null
  paymentElementLoaded = false

  submitting = false
  error = null

  stripeElementsOptions: StripeElementsOptions = {
    // https://aloecare.atlassian.net/browse/AL-5268
    mode: 'setup',
    currency: 'usd',
    locale: 'en',
    setupFutureUsage: 'off_session',
    paymentMethodCreation: 'manual',
    paymentMethodTypes: ['card'],
    fonts: [{cssSrc: 'https://fonts.googleapis.com/css?family=Nunito'}],
    appearance: {
      variables: {
        fontFamily: 'Nunito, sans-serif',
        colorText: '#000',
        colorPrimary: '#42c4d7',
        colorDanger: '#fd5248',
        fontSizeBase: '17px',
        borderRadius: '9px',
        spacingUnit: '6px',
      },
    },
  }

  stripePaymentElementOptions: StripePaymentElementOptions = {
    defaultValues: {
      billingDetails: {
        address: {
          country: 'US',
        },
      },
    },
  }

  get setupIntentClientSecret(): string {
    // Passed in via URL, for example after 3DS redirect.
    return new URLSearchParams(window.location.search).get('setup_intent_client_secret')
  }

  async mounted() {
    this.stripe = await loadStripe(window.BOOT.stripePublishableKey)

    if (this.setupIntentClientSecret) {
      // Handle setupIntent status, and if it redirects, we shouldn't show the payment form.
      let redirecting = await this.handleAlreadyCreatedSetupIntent(this.setupIntentClientSecret)
      if (redirecting) return
    }

    this.elements = this.stripe.elements(this.stripeElementsOptions as any)
    this.paymentElement = this.elements.create('payment', this.stripePaymentElementOptions)
    this.paymentElement.mount('#payment-element')
    this.paymentElement.on('ready', () => (this.paymentElementLoaded = true))
  }

  async handleAlreadyCreatedSetupIntent(clientSecret: string): Promise<boolean> {
    const {setupIntent} = await this.stripe.retrieveSetupIntent(clientSecret)

    console.log('[CheckoutStepBilling].handleAlreadyCreatedSetupIntent', {setupIntent})

    switch (setupIntent.status) {
      case 'succeeded':
        this.handleSucessUrlRedirect()
        return true

      case 'requires_payment_method':
        this.error = 'There was an issue validating your card details. Please try another card.'
        return false

      case 'requires_action':
        // Do 3DS secure banking redirect.
        const {error} = await this.stripe.handleNextAction({
          clientSecret: clientSecret,
        })

        if (error) {
          this.error = error
          return false
        }
        return true

      default:
        console.error('CheckoutStepBilling].handleAlreadyCreatedSetupIntent', {setupIntent})
        this.error = setupIntent.last_setup_error
        return false
    }
  }

  async submit() {
    this.error = null
    this.submitting = true

    // Trigger form validation and wallet collection
    let {error: elementError} = await this.elements.submit()
    if (elementError) {
      this.error = elementError
      this.submitting = false
      return
    }

    // Create the PaymentMethod using the details collected by the Payment Element
    let {error: paymentMethodError, paymentMethod} = await this.stripe.createPaymentMethod({
      elements: this.elements,
    })

    if (paymentMethodError) {
      this.error = paymentMethodError
      this.submitting = false
      return
    }

    console.log('[CheckoutStepBilling]', {paymentMethodError, paymentMethod})

    // Insert the payment method ID and values into form in django template so it gets submitted to the server
    // this also makes the page easier to unit test.
    let $form = $('#checkout-stripeForm')

    if (!$form.length) {
      console.error('[CheckoutStepBilling] form element not found!')
      return
    }

    $form.find('[name=stripe_token]').val(paymentMethod.id)
    $form.find('[name=exp_month]').val(paymentMethod.card.exp_month)
    $form.find('[name=exp_year]').val(paymentMethod.card.exp_year)
    $form.find('[name=last4]').val(paymentMethod.card.last4)

    $form.submit()
  }

  handleSucessUrlRedirect() {
    if (this.successUrlMethod == 'GET') {
      // Simple redirect
      window.location.href = this.successUrl
    } else if (this.successUrlMethod == 'POST') {
      // Create a form and submit via POST to the sucess url.
      $(`<form action="${this.successUrl}" method="POST"></form>`)
        .append($('[name=csrfmiddlewaretoken]').clone())
        .appendTo('body')
        .submit()
    }
  }
}
