
import Vue from 'vue'
import {Component, Prop, Watch} from 'vue-property-decorator'
import {Card, createToken} from 'vue-stripe-elements-plus'
import ElementChangeResponse = stripe.elements.ElementChangeResponse

@Component({
  components: {Card},
})
export default class CheckoutStepBilling extends Vue {
  @Prop()
  stripePublishableKey!: string

  @Prop()
  serverError!: any

  @Prop({default: 'Enter your billing information'})
  headerCopy!: string

  @Prop({default: ''})
  stepIndicatorCopy!: string

  stripeOptions = {
    // https://github.com/fromAtoB/vue-stripe-elements
    // see https://stripe.com/docs/stripe.js#element-options for details
    elements: {
      fonts: [{cssSrc: 'https://fonts.googleapis.com/css?family=Space+Mono'}],
    },
    style: {
      base: {
        fontSize: '17px',
        fontFamily: '"Space Mono", monospace',
      },
    },
  }

  complete = false
  submitting = false
  error = null
  validationError = null

  onInputChange(change: ElementChangeResponse) {
    this.complete = change.complete
    this.validationError = change.error ? change.error.message : ''
  }

  async submit() {
    this.error = null
    this.submitting = true

    // createToken returns a Promise which resolves in a result object with
    // either a token or an error key.
    // See https://stripe.com/docs/api#tokens for the token object.
    // See https://stripe.com/docs/api#errors for the error object.
    // More general https://stripe.com/docs/stripe.js#stripe-create-token.

    const {token, error, ...data} = await createToken()
    console.log('[CheckoutStepBilling] createToken', {token, error, data})

    if (token) {
      this.stripeTokenHandler(token)
    } else {
      this.error = error
      this.submitting = false
    }
  }

  stripeTokenHandler(token) {
    /**
     * Submits stripeToken using form POST.
     */
    console.log('[CheckoutStepBilling] stripeTokenHandler', token)

    // Insert the token ID and values into form in django template so it gets submitted to the server
    // this also makes the page easier to unit test.
    const $form = $('#checkout-stripeForm')

    if (!$form.length) {
      console.error('[CheckoutStepBilling] form element not found!')
      return
    }

    $form.find('[name=stripe_token]').val(token.id)
    $form.find('[name=exp_month]').val(token.card.exp_month)
    $form.find('[name=exp_year]').val(token.card.exp_year)
    $form.find('[name=last4]').val(token.card.last4)
    $form.find('[name=zip]').val(token.card.address_zip)

    $form.submit()
  }
}
