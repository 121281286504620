
import Vue from 'vue'
import {Component, Prop, Watch} from 'vue-property-decorator'

@Component({})
export default class PasswordInput extends Vue {
  @Prop({default: 'password'})
  name: string

  @Prop({default: ''})
  placeholder: string

  value = ''
  masked = true

  get type() {
    return this.masked ? 'password' : 'text'
  }
}
